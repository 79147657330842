<template>
  <v-container v-if="user" class="dashboard default--text">
    <category-block
      v-if="!drawer"
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <h1 v-if="showTitle">{{ `Ciao ${user.firstName}` }}</h1>
    <h6 v-if="showSubtitle" class="mb-4 mb-sm-0">
      {{ $t("dashboard.subtitle") }}
    </h6>
    <v-row :class="{ 'my-8': showTitle }" no-gutters>
      <v-col
        cols="12"
        :sm="drawer ? '12' : '6'"
        :md="drawer ? '12' : '8'"
        :lg="drawer ? '12' : '6'"
        :xl="drawer ? '12' : '3'"
        v-if="showCard && user.fidelityCard"
      >
        <transition name="flip">
          <div
            class="socioCard pa-3 my-2"
            :class="{ 'mx-2': !drawer }"
            :key="expandedBarcode"
            @click="expandedBarcode = !expandedBarcode"
          >
            <div class="user">{{ user.firstName }} {{ user.lastName }}</div>
            <strong class="white--text text-h4"
              >{{ $t("dashboard.points") }}
              {{ findFirstValidEntry(user?.userPoints) }}</strong
            >
            <div class="logo">
              <img
                src="/logo/logo_ccoop.svg"
                alt="Logo Coop Trentino"
                width="90px"
                height="auto"
              />
            </div>
            <div class="barcode" :class="{ expanded: expandedBarcode }">
              <barcode v-bind:value="user.fidelityCard" class="fidelity-ean">
                Error
              </barcode>
            </div>
          </div>
        </transition>
      </v-col>
      <template v-for="(card, idx) in cards">
        <v-col
          :key="idx"
          v-if="
            card.active &&
              (!card.isPolverization ||
                (card.isPolverization && canShowPointsPolvLiguria))
          "
          cols="12"
          :sm="drawer ? '12' : '6'"
          :md="drawer ? '12' : '4'"
          :lg="drawer ? '12' : '3'"
        >
          <DashboardCard
            :navigation="card.navigation"
            :cardData="card"
            :drawer="drawer"
          />
        </v-col>
      </template>
    </v-row>

    <category-block
      v-if="!drawer"
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />

    <div
      v-if="$platform_is_cordova && isBetaTester"
      class="mt-10 development-link front-weight-bold"
    >
      <a :href="developmentLink">Apri versione di test</a>
    </div>
  </v-container>
</template>
<style lang="scss">
.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}
.dashboard {
  h1 {
    font-weight: 600;
  }
  h6 {
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    h6 {
      font-size: 20px;
      line-height: 30px;
    }
  }
  .fidelityPoints {
    background: transparent
      linear-gradient(180deg, #f7f7f7 0%, var(--v-primary-base) 100%) 0% 0%
      no-repeat padding-box;
    .v-card__title {
      color: $white !important;
    }
    .v-card__subtitle {
      color: $white !important;
      .counter {
        font-size: 35px;
        color: var(--v-primary-base);
        font-weight: bold;
      }
    }
  }
  .donateTree,
  .donatePoints {
    .v-card__title {
      color: $white !important;
    }
    .v-card__subtitle {
      color: $white !important;
      .counter {
        font-size: 35px;
        color: $white;
        font-weight: bold;
        margin-top: 10px;
      }
    }
    a,
    .v-icon {
      color: $white !important;
    }
  }

  .donateTree {
    background-image: url("/img_layout/menu/card/sostenibilita/dona-un-albero.png");
    background-size: cover;
  }
  .donatePoints {
    background-color: #da1b5c;
  }
  .socioCard {
    cursor: pointer;
    height: 200px;
    border-radius: 8px;
    background: transparent
      linear-gradient(180deg, #f7f7f7 0%, var(--v-primary-base) 100%) 0% 0%
      no-repeat padding-box;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 50px 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    align-items: center;
  }

  .barcode {
    grid-area: 2 / 2 / 3 / 3;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-color: $white;
    display: flex;
    transition: 500ms;
    .fidelity-ean {
      width: 100%;
      height: 100%;
      max-height: 116px;
      .vue-barcode-element {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
  }
  .barcode.expanded {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    z-index: 1;
    transition: 500ms;
    .fidelity-ean {
      max-height: fit-content;
    }
  }
  .user {
    grid-area: 1 / 1 / 2 / 2;
    font-size: 13px;
    font-weight: bold;
  }
  .logo {
    grid-area: 1 / 2 / 2 / 3;
    display: flex;
    justify-content: center;
    img {
      max-width: 150px;
    }
  }
  .desc {
    grid-area: 2 / 1 / 3 / 2;
    text-transform: uppercase;
  }
}
</style>
<script>
// @ is an alias to /src
import DashboardCard from "@/components/profile/DashboardCard.vue";
import categoryMixin from "~/mixins/category";
//import get from "lodash/get";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: { DashboardCard },
  mixins: [categoryMixin],
  props: {
    drawer: { type: Boolean, default: false },
    showTitle: { type: Boolean, default: true },
    showSubtitle: { type: Boolean, default: true },
    showCard: { type: Boolean, default: true }
  },
  data() {
    return {
      expandedBarcode: false,
      cards: null,
      defaultCards: [
        {
          active: true,
          name: "profile",
          title: "dashboard.profile.title",
          description: "dashboard.profile.description",
          icon: "profile",
          navigation: true,
          routeName: "ProfileUpdate",
          link: "dashboard.profile.link"
        },
        {
          active: true,
          name: "fidelity",
          title: "dashboard.fidelity.title",
          description: "dashboard.fidelity.description",
          icon: "card",
          navigation: true,
          routeName: "Fidelity",
          class: "fidelity",
          link: "dashboard.fidelity.link"
        },
        {
          active: true,
          name: "logout",
          title: "dashboard.logout.title",
          description: "",
          icon: "logout",
          routeName: "",
          navigation: false,
          link: ""
        }
      ],
      defaultCardsEcommerce: [
        {
          active: true,
          name: "profile",
          title: "dashboard.profile.title",
          description: "dashboard.profile.description",
          icon: "profile",
          navigation: true,
          routeName: "ProfileUpdate",
          link: "dashboard.profile.link"
        },
        {
          active: true,
          name: "fidelity",
          title: "dashboard.fidelity.title",
          description: "dashboard.fidelity.description",
          icon: "card",
          navigation: true,
          routeName: "Fidelity",
          class: "fidelity",
          link: "dashboard.fidelity.link"
        },
        // {
        //   active: true,
        //   name: "fidelityPoints",
        //   title: "dashboard.fidelityPoints.title",
        //   description: "",
        //   label: "dashboard.fidelityPoints.label",
        //   icon: "",
        //   navigation: true,
        //   routeName: "#",
        //   class: "fidelityPoints",
        //   link: "dashboard.fidelityPoints.link"
        // },
        // {
        //   active: false,
        //   isPolverization: true,
        //   name: "pointsPolv",
        //   title: "dashboard.pointsPolv.title",
        //   description: "dashboard.pointsPolv.description",
        //   icon: "plus",
        //   navigation: true,
        //   routeName: "PointsPolv",
        //   class: "points-polv",
        //   link: "dashboard.pointsPolv.link"
        // },
        // {
        //   active: true,
        //   name: "news",
        //   title: "dashboard.news.title",
        //   description: "dashboard.news.description",
        //   icon: "bellLight",
        //   navigation: true,
        //   routeName: "NewsListPage",
        //   link: "dashboard.orders.link"
        // },
        {
          active: true,
          name: "orders",
          title: "dashboard.orders.title",
          description: "dashboard.orders.description",
          icon: "cart",
          navigation: true,
          routeName: "Orders",
          link: "dashboard.orders.link"
        },
        // {
        //   active: true,
        //   name: "orderRefunds",
        //   title: "dashboard.orderRefunds.title",
        //   description: "dashboard.orderRefunds.description",
        //   icon: "pagamento",
        //   navigation: true,
        //   routeName: "Refunds",
        //   link: "dashboard.orderRefunds.link"
        // },
        // {
        //   active: true,
        //   name: "paymentMethods",
        //   title: "dashboard.paymentMethods.title",
        //   description: "dashboard.paymentMethods.description",
        //   icon: "wallet",
        //   navigation: true,
        //   routeName: "MyPaymentMethods",
        //   link: "dashboard.paymentMethods.link"
        // },
        {
          active: true,
          name: "lists",
          title: "dashboard.lists.title",
          description: "dashboard.lists.description",
          icon: "liste",
          navigation: true,
          routeName: "Lists",
          link: "dashboard.lists.link"
        },

        {
          active: true,
          name: "addresses",
          title: "dashboard.addresses.title",
          description: "dashboard.addresses.description",
          icon: "location",
          navigation: true,
          routeName: "Addresses",
          link: "dashboard.addresses.link"
        },
        // {
        //   active: true,
        //   name: "gifts",
        //   title: "dashboard.gifts.title",
        //   description: "dashboard.gifts.description",
        //   icon: "vantaggi",
        //   navigation: true,
        //   routeName: "ProfileGift",
        //   link: "dashboard.gifts.link"
        // },
        // {
        //   active: true,
        //   name: "cards",
        //   title: "dashboard.cards.title",
        //   description: "dashboard.cards.description",
        //   icon: "creditCard",
        //   navigation: true,
        //   routeName: "MyPaymentMethods",
        //   link: "dashboard.cards.link"
        // },

        // {
        //   active: true,
        //   name: "billing",
        //   title: "dashboard.billing.title",
        //   description: "dashboard.billing.description",
        //   icon: "invoice",
        //   navigation: true,
        //   routeName: "InvoiceData",
        //   class: "billing",
        //   link: "dashboard.billing.link"
        // },
        // {
        //   active: true,
        //   name: "invite",
        //   title: "dashboard.invite.title",
        //   description: "dashboard.invite.description",
        //   icon: "addUser",
        //   navigation: true,
        //   routeName: "Invite",
        //   class: "invite",
        //   link: "dashboard.Invite.link"
        // },
        {
          active: true,
          name: "logout",
          title: "dashboard.logout.title",
          description: "",
          icon: "logout",
          routeName: "",
          navigation: false,
          link: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      isBetaTester: "cart/isBetaTester",
      getApplicationConfig: "custom/getApplicationConfig",
      isPointPolvLiguriaActive: "custom/isPointPolvLiguriaActive",
      user: "cart/getUser"
    }),

    canShowPointsPolvLiguria() {
      return this.isPointPolvLiguriaActive && this.user.companyId == 2;
    },
    developmentLink() {
      // eslint-disable-next-line no-undef
      let platform = device ? device.platform.toLowerCase() : "ios";
      return "/mobile/develop/" + platform + "/index.html";
    }
  },
  methods: {
    findFirstValidEntry(userPoints) {
      for (const key in userPoints) {
        if (userPoints[key].value && userPoints[key].value !== 0) {
          return userPoints[key].value;
        }
      }
      return 0;
    }
  },
  mounted() {
    if (this.mainTenantName === "clickandcoop") {
      this.cards = this.defaultCardsEcommerce;
    } else {
      this.cards = this.defaultCards;
    }
  }
};
</script>
